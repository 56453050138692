body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.buttonPrimaryStyle {
	width: 170px;
	color: white;
	align-self: baseline;
}


.styleNav {
	width: 160px;
	padding-left: 2%;
}
.styleTextNav {
	color: white;
}

.textInput {
	width: 120px;
	color: black;
	padding-left: 5%;
}


.textInput {
	width: 120px;
	color: black;
	padding-left: 5%;
}

.label {
	color: black;
}

.backgroundColor {
	background-color: #282c34;
}

.textareaSize {
	font-size: 10px;
}
